import React from 'react';
import { useNavigate } from 'react-router-dom';

const CostPrice = () => {
  const services = [
    { name: 'Dog Day Care', price: '₹500 per day' },
    { name: 'Boarding', price: '₹800 per day' },
    { name: 'Grooming', price: '₹1,200 per session' },
    { name: 'Permanent Pet Adoption', price: '₹15,000 onwards' },
    { name: 'Stud Service', price: '₹5,000 per session' },
  ];

  const memberships = [
    {
      duration: '1 Month',
      price: '₹2,000',
      benefits: ['10% discount on grooming', 'Priority booking for services'],
      servicesIncluded: ['5 Dog Day Care sessions', '2 Grooming sessions'],
    },
    {
      duration: '3 Months',
      price: '₹5,500',
      benefits: [
        '15% discount on grooming and boarding',
        'Priority booking for services',
        '1 free health check-up',
      ],
      servicesIncluded: ['15 Dog Day Care sessions', '6 Grooming sessions', '1 Boarding session'],
    },
    {
      duration: '6 Months',
      price: '₹10,000',
      benefits: [
        '20% discount on grooming and boarding',
        'Priority booking for services',
        '2 free health check-ups',
        'Exclusive adoption offers',
      ],
      servicesIncluded: [
        '30 Dog Day Care sessions',
        '12 Grooming sessions',
        '3 Boarding sessions',
      ],
    },
    {
      duration: '1 Year',
      price: '₹18,000',
      benefits: [
        '25% discount on all services',
        'Priority booking for services',
        'Free health check-ups (once a month)',
        'Exclusive offers on stud services',
      ],
      servicesIncluded: [
        'Unlimited Dog Day Care sessions',
        '24 Grooming sessions',
        '5 Boarding sessions',
        '1 Exclusive adoption offer',
      ],
    },
  ];

  const generalBenefits = [
    'Priority booking for services',
    'Discounts on grooming and boarding',
    'Free health check-ups (once a month)',
    'Exclusive offers on adoption and stud services',
    'Dedicated customer support',
  ];

  const navigate = useNavigate();

  const handleViewTerms = () => {
    navigate('/terms-condition');
    // Add navigation logic to redirect to the terms and conditions page
  };

  return (
    <div className="container mx-auto py-12 px-6 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
      {/* Header */}
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Cost Price for Our Services</h1>

      {/* Services Section - Table */}
      <div className="bg-white rounded-lg shadow-lg p-8 mb-12 mx-auto w-full max-w-4xl">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Our Services</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto text-gray-700 text-center">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4">Service</th>
                <th className="py-2 px-4">Price</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4">{service.name}</td>
                  <td className="py-2 px-4 font-bold text-blue-600">{service.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Membership Plans Section - Table */}
      <div className="bg-white rounded-lg shadow-lg p-8 mb-12 mx-auto w-full max-w-4xl">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Membership Plans</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto text-gray-700 text-center">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4">Duration</th>
                <th className="py-2 px-4">Price</th>
                <th className="py-2 px-4">Included Services</th>
                <th className="py-2 px-4">Additional Benefits</th>
              </tr>
            </thead>
            <tbody>
              {memberships.map((membership, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4">{membership.duration}</td>
                  <td className="py-2 px-4 font-bold text-green-600">{membership.price}</td>
                  <td className="py-2 px-4">
                    <ul className="list-none text-left">
                      {membership.servicesIncluded.map((service, i) => (
                        <li key={i} className="flex items-center">
                          <span className="text-green-600 mr-2">✔</span>
                          {service}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="py-2 px-4">
                    <ul className="list-none text-left">
                      {membership.benefits.map((benefit, i) => (
                        <li key={i} className="flex items-center">
                          <span className="text-green-600 mr-2">✔</span>
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* General Membership Benefits */}
      <div className="bg-white rounded-lg shadow-lg p-8 mb-12 mx-auto w-full max-w-4xl">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">General Benefits of Membership</h2>
        <ul className="list-none text-left space-y-2">
          {generalBenefits.map((benefit, index) => (
            <li key={index} className="flex items-center">
              <span className="text-green-600 mr-2">✔</span>
              {benefit}
            </li>
          ))}
        </ul>
        <p className="text-sm text-gray-500 mt-4 text-center">* Terms and conditions apply.</p>
        <div className="text-center">
          <button
            onClick={handleViewTerms}
            className="mt-6 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
          >
            View Terms and Conditions
          </button>
        </div>
      </div>
    </div>
  );
};

export default CostPrice;
