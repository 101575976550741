
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // AOS styles
import { Link, useNavigate } from "react-router-dom";
import GoogleReviews from "./GoogleReviews";
import ServiceCount from "./ServiceCount";

const Home = () => {

  const navigate = useNavigate()
  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out" });
  }, []);

  return (
    <section className="hero text-center py-10 px-4 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
      {/* Hero Section */}
      <div data-aos="fade-down">
        <h2 className="text-3xl sm:text-3xl md:text-4xl font-bold text-purple-800">
          Welcome to <span className="text-blue-600">Tuffy Kennels and Boarding</span>
        </h2>
        <p className="mt-4 text-xl sm:text-2xl md:text-3xl text-gray-600" data-aos="fade-up" data-aos-delay="200">
          Your dog's home away from home. We specialize in daycare, boarding, grooming, breed sales, and stud services.
        </p>
        {/* Tagline */}
        <p className="mt-6 text-xl sm:text-2xl md:text-3xl font-semibold  italic border-l-4 pl-6 text-gray-800" data-aos="fade-up" data-aos-delay="300">
          <span className="font-serif text-2xl text-gray-600">"We breed your dreams as our passion"</span>
        </p>
        <Link to='/contact-us'>
          <span
            className="mt-8 inline-block bg-blue-600 text-white py-2 px-6 rounded-md text-lg sm:text-xl hover:bg-blue-700 transition"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Send Enquiry
          </span>
        </Link>
      </div>

      {/* Services Section */}
      <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {/* Dog Daycare */}
        <Link to="/day-care">
          <div
            onClick={() => {
              navigate('/day-care')
            }}
            className="feature bg-gradient-to-r from-yellow-400 via-orange-500 to-red-600 p-6 rounded-lg shadow-lg text-white"
            data-aos="fade-up"
          >
            <h2 className="text-2xl font-semibold">Dog Daycare</h2>
            <p className="mt-4">
              Your dog will enjoy a day full of play, socializing, and fun in a safe and controlled environment.
              We ensure your pet stays engaged and happy.
            </p>
          </div>
        </Link>

        {/* Boarding */}
        <Link to="/dog-boardings">
          <div
            className="feature bg-gradient-to-r from-teal-400 via-blue-600 to-indigo-700 p-6 rounded-lg shadow-lg text-white"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h2 className="text-2xl font-semibold">Boarding Services</h2>
            <p className="mt-4">
              Our boarding services offer a home-like experience with cozy accommodations, 24/7 supervision,
              and personalized care for each pet.
            </p>
          </div>
        </Link>

        {/* Grooming */}
        <Link to="/dog-grooming">
          <div
            className="feature bg-gradient-to-r from-purple-400 via-pink-500 to-red-600 p-6 rounded-lg shadow-lg text-white"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h2 className="text-2xl font-semibold">Grooming Services</h2>
            <p className="mt-4">
              From basic grooming to full spa treatments, we offer a range of services to keep your dog looking
              and feeling their best.
            </p>
          </div>
        </Link>
      </div>


      <div className="space-y-8 mt-12 text-left">
        {/* Breeds for Sale */}

        <div className="flex  flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="300">
          {/* Left Content */}
          <div className="flex-1 flex flex-col justify-center mb-6 md:mb-0">
            <h2
              className="text-3xl sm:text-4xl text-center text-blue-700 font-semibold"
              data-aos="fade-up"
            >
              Puppies for Sale
            </h2>
            <div className="mt-8 text-center">
              <p
                className="text-xl sm:text-2xl text-gray-600 max-w-3xl mx-auto"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                We offer premium, healthy puppies of various breeds. All our puppies come with a health guarantee,
                and are raised in a loving environment.
              </p>
              <Link to='/our-breeds-and-stud-service'>
                <span
                  className="mt-8 inline-block bg-blue-600 text-white py-2 px-6 rounded-md text-lg sm:text-xl hover:bg-blue-700 transition"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  View Available Puppies
                </span>
              </Link>
            </div>
          </div>

          {/* Right Image */}
          <div className="flex-1 md:ml-6">
            <img
              src="https://img.freepik.com/premium-photo/little-beautiful-girl-kicking-many-akita-inu-puppies-beige-background-with-copy-space_1429-24062.jpg?w=996"
              alt="Spacious Accommodations"
              className="rounded-lg object-cover w-full h-full"
            />
          </div>
        </div>

        {/* Stud Service */}
        <div className="flex  flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="300">


          {/* Right Image */}
          <div className="flex-1 md:ml-6">
            <img
              src="https://img.freepik.com/free-photo/stylish-bearded-middle-age-male-irish-setter_613910-8101.jpg?t=st=1734111540~exp=1734115140~hmac=77ced97dce39f9ce510001033d3387c662ade81940dcd5c02e3f16b7c6b349d0&w=996"
              alt="Spacious Accommodations"
              className="rounded-lg object-cover w-full h-full"
            />
          </div>

          {/* Left Content */}
          <div className="flex-1 flex flex-col justify-center mb-6 md:mb-0">
            <h2
              className="text-3xl sm:text-4xl text-center text-green-700 font-semibold"
              data-aos="fade-up"
            >
              Stud Service
            </h2>
            <div className="mt-8 text-center">
              <p
                className="text-xl sm:text-2xl text-gray-600 max-w-3xl mx-auto"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                We offer stud services for selected breeds. Our males are health-tested and of superior pedigree,
                ready to produce healthy litters.
              </p>
              <Link to='/contact-us'>
                <span
                  className="mt-8 inline-block bg-green-600 text-white py-2 px-6 rounded-md text-lg sm:text-xl hover:bg-green-700 transition"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Send Enquiry
                </span>
              </Link>
            </div>
          </div>
        </div>

        {/* permanent bording */}

        <div className="flex  flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="300">

          {/* Left Content */}
          <div className="flex-1 flex flex-col justify-center mb-6 md:mb-0">
            <h2
              className="text-3xl sm:text-4xl text-center text-green-700 font-semibold"
              data-aos="fade-up"
            >
              Permanent Boarding
            </h2>
            <div className="mt-8 text-center">
              <p
                className="text-xl sm:text-2xl text-gray-600 max-w-3xl mx-auto"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                At Tuffy Kennels and Boarding, we understand that sometimes life circumstances require a permanent boarding solution for your beloved pet. Whether you're relocating, facing personal challenges, or simply need a trusted place for your dog to live happily, we offer a permanent boarding service designed to provide a second home filled with love, care, and attention.
              </p>
              <Link to='/contact-us'>
                <span
                  className="mt-8 inline-block bg-green-600 text-white py-2 px-6 rounded-md text-lg sm:text-xl hover:bg-green-700 transition"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Send Enquiry
                </span>
              </Link>
            </div>
          </div>
          {/* Right Image */}
          <div className="flex-1 md:ml-6">
            <img
              src="https://img.freepik.com/free-photo/lovely-dog-posing-garden_23-2147997385.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="Spacious Accommodations"
              className="rounded-lg object-cover w-full h-full"
            />
          </div>


        </div>

        <div className=" py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6 text-gray-800">Explore Our Services</h2>
          <p className="text-lg text-gray-600 mb-8">
            Learn more about the cost of our services and membership benefits.
          </p>
          <div className="flex justify-center space-x-6">
            {/* Button for Cost Price */}
            <Link to="/cost-price">
              <button className="bg-blue-500 text-white px-8 py-4 rounded-full shadow-md hover:bg-blue-600 transition-all duration-300">
                View Cost Price
              </button>
            </Link>
            {/* Button for Membership */}
            <Link to="/membership">
              <button className="bg-orange-500 text-white px-8 py-4 rounded-full shadow-md hover:bg-orange-600 transition-all duration-300">
                View Membership
              </button>
            </Link>
          </div>
        </div>
      </div>

      </div>

      {/* our facilities */}

      <div className="max-w-7xl py-12 mx-auto px-6 text-center">
        <h2 className="text-3xl font-bold text-blue-800 mb-8" data-aos="fade-up">
          Our Facilities
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {/* Facility 1 */}
          <div
            className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            data-aos="fade-up"
          >
            <img
              src="https://img.freepik.com/free-photo/washing-pet-dog-home_23-2149627259.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="Regular Bath"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-green-800 mb-2">
                Regular Bath
              </h3>
              <p className="text-gray-600">
                Ensuring your pets stay clean and refreshed.
              </p>
            </div>
          </div>

          {/* Facility 2 */}
          <div
            className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            data-aos="fade-up"
          >
            <img
              src="https://img.freepik.com/free-vector/laptop-web-security_1284-12644.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="CCTV Monitoring"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-green-800 mb-2">
                CCTV Monitoring
              </h3>
              <p className="text-gray-600">
                24/7 surveillance for your peace of mind.
              </p>
            </div>
          </div>

          {/* Facility 3 */}
          <div
            className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            data-aos="fade-up"
          >
            <img
              src="https://img.freepik.com/premium-photo/close-up-dog-feeding-indoors_23-2151679658.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="Meals and Playtime"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-green-800 mb-2">
                Meals and Playtime
              </h3>
              <p className="text-gray-600">
                Balanced meals and supervised play sessions daily.
              </p>
            </div>
          </div>

          {/* Facility 4 */}
          <div
            className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            data-aos="fade-up"
          >
            <img
              src="https://img.freepik.com/premium-vector/uploading-youtube-videos_22110-606.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="2 Videos Per Day"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-green-800 mb-2">
                2 Videos Per Day
              </h3>
              <p className="text-gray-600">
                Get daily updates with videos of your pet.
              </p>
            </div>
          </div>

          {/* Facility 5 */}
          <div
            className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            data-aos="fade-up"
          >
            <img
              src="https://img.freepik.com/free-photo/volunteer-girl-preparing-work-she-is-holding-rake-checkig-it-up-wearing-rubber-gloves_1157-49872.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="Cleaner Environment"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-green-800 mb-2">
                Cleaner Environment
              </h3>
              <p className="text-gray-600">
                Hygienic surroundings to ensure your pet’s health.
              </p>
            </div>
          </div>


          {/* Facility 6 */}
          <div
            className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            data-aos="fade-up"
          >
            <img
              src="https://img.freepik.com/free-vector/senior-people-getting-checkup-hospital_53876-66259.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
              alt="Personalized Care"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-green-800 mb-2">
                Personalized Care
              </h3>
              <p className="text-gray-600">
                Individual attention to match each pet's unique needs and preferences.
              </p>
            </div>
          </div>
        </div>
      </div>



      <ServiceCount />
      <GoogleReviews />
    </section>
  );
};

export default Home;
