import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import owner from "./images/owner.jpg"

const AboutUs = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section id="about" className="py-12 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
            <div className="container px-4 sm:px-6 lg:px-8">
                {/* Title Section */}
                <div className="text-center" data-aos="fade-down">
                    <h2 className="text-3xl sm:text-3xl md:text-4xl font-bold text-purple-800">
                        About <span className="text-blue-600">Tuffy Kennels and Boardings</span>
                    </h2>
                    <p className="mt-4 text-xl sm:text-2xl text-gray-700">
                        At Tuffy Kennels, we provide exceptional care, comfort, and companionship for your furry friends.
                    </p>
                </div>


{/* {meet our founder} */}

<div className="mt-16 flex flex-col lg:flex-row items-center gap-8 p-4" data-aos="fade-up">
    <div className="w-full lg:w-1/2 text-center lg:text-left">
        <h2 className="text-3xl font-semibold text-gray-800">Meet Our Founder</h2>
        <p className="mt-4 text-lg text-gray-600">
            Our founder, Abhishek C, has dedicated their life to creating a place where the love for animals thrives. With years of experience in breeding and caring for puppies, they have built Tuffy Kennels and Boarding with a passion for providing quality and loving homes for puppies.
        </p>
        <p className="mt-4 text-lg text-gray-600">
            Abhishek C believes in the importance of responsible breeding, ensuring that each puppy is raised in a safe, healthy, and nurturing environment. Their vision is to connect families with their perfect furry companions, one puppy at a time.
        </p>
    </div>

    <div className="w-full lg:w-1/2 flex justify-center mt-8 lg:mt-0">
        <img src={owner} alt="Founder Image" className="h-72 w-72 rounded-full object-cover shadow-lg" />
    </div>
</div>

{/* Meet Our Team */}
<div className="mt-16 p-4" data-aos="fade-up">
  <h2 className="text-3xl font-semibold text-center text-gray-800">Meet Our Team</h2>
  <p className="mt-4 text-lg text-center text-gray-600">
    Our dedicated team of professionals is passionate about providing top-notch care and services for our furry friends. Here's a look at the people behind Tuffy Kennels and Boarding.
  </p>

  {/* Team Members Section */}
  <div className="mt-8 grid grid-cols-3 gap-8">
    {/* Team Member 1 */}
    <div className="flex flex-col items-center text-center">
      <img
        src="https://via.placeholder.com/150" // Dummy Image 1
        alt="Team Member 1"
        className="h-40 w-40 rounded-full object-cover shadow-lg"
      />
      <h3 className="mt-4 text-xl font-semibold text-gray-800">Name 1 </h3>
      <p className="text-lg text-gray-600">Co-Founder</p>
    </div>

    {/* Team Member 2 */}
    <div className="flex flex-col items-center text-center">
      <img
        src="https://via.placeholder.com/150" // Dummy Image 2
        alt="Team Member 2"
        className="h-40 w-40 rounded-full object-cover shadow-lg"
      />
      <h3 className="mt-4 text-xl font-semibold text-gray-800">Name 2</h3>
      <p className="text-lg text-gray-600">Grooming Specialist</p>
    </div>

    {/* Team Member 3 */}
    <div className="flex flex-col items-center text-center">
      <img
        src="https://via.placeholder.com/150" // Dummy Image 3
        alt="Team Member 3"
        className="h-40 w-40 rounded-full object-cover shadow-lg"
      />
      <h3 className="mt-4 text-xl font-semibold text-gray-800">Name 3</h3>
      <p className="text-lg text-gray-600">Kennel Manager</p>
    </div>
  </div>
</div>



                {/* Mission Section */}
                <div className="mt-16 flex flex-col lg:flex-row items-center gap-8" data-aos="fade-right">
                    <div className="lg:w-1/2">
                        <img
                            src="https://img.freepik.com/free-photo/pet-owner-being-affectionate-towards-their-dog_23-2150984481.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid" // Replace with an actual image
                            alt="Mission"
                            className="rounded-lg shadow-lg"
                        />
                    </div>
                    <div className="lg:w-1/2">
                        <h3 className="text-2xl md:text-3xl font-bold text-purple-800">Our Mission</h3>
                        <p className="mt-4 text-sm md:text-base text-gray-700">
                            At Tuffy Kennels, we aim to provide top-notch care and unmatched facilities for your beloved
                            pets. We’re committed to ensuring a joyful and secure environment for dogs of all breeds and
                            sizes while building a community of pet lovers.
                        </p>
                    </div>
                </div>

               

                {/* Features Block */}
                <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {[
                        {
                            icon: "🏡",
                            title: "Spacious Facilities",
                            desc: "Large, clean spaces for dogs to relax and play in comfort.",
                        },
                        {
                            icon: "🛏️",
                            title: "Cozy Stay",
                            desc: "Comfortable bedding and personalized attention for every dog.",
                        },
                        {
                            icon: "🍖",
                            title: "Nutritious Meals",
                            desc: "Healthy, tasty, and vet-approved meals for all our guests.",
                        },
                        {
                            icon: "👩‍⚕️",
                            title: "Health First",
                            desc: "Regular check-ups and care for your dog’s well-being.",
                        },
                    ].map((feature, index) => (
                        <div
                            key={index}
                            className="p-6 bg-gradient-to-tr from-purple-100 to-blue-100 rounded-lg shadow-lg text-center hover:scale-105 transition-transform duration-300"
                            data-aos="zoom-in"
                            data-aos-delay={`${index * 100}`}
                        >
                            <div className="text-5xl">{feature.icon}</div>
                            <h4 className="mt-4 text-xl md:text-2xl font-bold text-purple-800">{feature.title}</h4>
                            <p className="mt-2 text-sm md:text-base text-gray-700">{feature.desc}</p>
                        </div>
                    ))}
                </div>





                {/* Customer Testimonials */}
                <div
                    className="mt-16 text-center bg-gradient-to-r from-blue-500 to-purple-500 p-8 rounded-lg shadow-lg text-white"
                    data-aos="fade-up"
                >
                    <h3 className="text-xl md:text-3xl font-bold">Hear from Our Happy Customers!</h3>
                    <p className="mt-4 text-sm md:text-lg">
                        "Tuffy Kennels has been a lifesaver for us. Our Golden Retriever loves staying here, and we trust
                        them with his care." - Darshan Shetty
                    </p>
                </div>

                {/* Call-to-Action Block */}
                <div
                    className="mt-16 text-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white"
                    data-aos="fade-up"
                >
                    <h3 className="text-xl md:text-3xl font-bold">Join the Tuffy Kennels Family Today!</h3>
                    <p className="mt-4 text-sm md:text-lg">
                        Whether you’re looking for the perfect puppy or a safe place for your dog to stay, we’re here for
                        you. Contact us now!
                    </p>
                    <Link to="/contact-us">
                        <button className="mt-6 px-6 py-3 bg-white text-blue-600 rounded-lg shadow hover:bg-gray-100">
                            Get Started
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
