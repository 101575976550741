import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
// import tuffynew from "./images/tuffy3.png";
import tuffynewlogo from "./images/tuffy2.jpg"
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="py-12 bg-gray-700 text-white ">
      <div className="container mx-auto px-6">
        {/* Main footer content container */}
        <div className="flex flex-col sm:flex-row justify-between gap-8">

          {/* Address */}
          <div className="flex-1">
            <h3 className="text-xl text-left font-semibold">Our Address</h3>
            <div className="mt-2 text-sm space-y-4">
              {/* Location Icon */}
              <div className="flex text-left items-center">
                <FaMapMarkerAlt className="mr-2 " size={30} />
                <p>Tuffy Kennels and Boarding, KEB ROAD, 9th Cross Rd, Anjana Nagar, Bengaluru, Karnataka 560091</p>
              </div>

              {/* Phone Icon */}
              <div className="flex items-center">
                <FaPhoneAlt className="mr-2 " size={20} />
                <p>+916363507022</p> {/* Replace with the actual phone number */}
              </div>

              {/* Email Icon */}
              <div className="flex items-center">
                <FaEnvelope className="mr-2 " size={20} />
                <p>tuffyabhishek007@gmail.com</p> {/* Replace with the actual email address */}
              </div>
            </div>
              {/* Social Media Icons */}
        <div className="mt-8 flex justify-start space-x-6">
          <a
            href="https://www.facebook.com/AbhishekTuffy?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
            aria-label="Facebook"
          >
            <FaFacebook size={30} />
          </a>
          <a
            href="https://www.instagram.com/tuffy_unofficial?igsh=MTR4cmg2aHh5MHphbA=="
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-700 transition-colors duration-300"
            aria-label="Instagram"
          >
            <FaInstagram size={30} />
          </a>
          <a
            href="https://www.youtube.com/@tuffyrescues4496"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-500 hover:text-red-700 transition-colors duration-300"
            aria-label="YouTube"
          >
            <FaYoutube size={30} />
          </a>
        </div>
          </div>

          {/* Company Information */}
          <div className="flex-1">
            <div className="flex justify-center items-center mb-4">
              <img className="w-36 rounded-lg " src={tuffynewlogo} alt="Tuffy Logo" />
            </div>
            {/* <p className="mt-2 text-sm">
              Tuffy Kennels provides top-notch dog boarding, grooming, and care. We ensure your furry friends receive the best attention, while you enjoy peace of mind knowing they're in good hands. We also offer puppies for sale, specializing in healthy, happy breeds.
            </p> */}
          </div>

          {/* Quick Links */}
          <div className="flex-1">
            <h3 className="text-xl text-left font-semibold">Quick Links</h3>
            <ul  className="mt-2 text-sm text-left space-y-6 ">
              <Link to="/">
                <li className="hover:text-yellow-300 cursor-pointer">
                  Home
                </li>
              </Link>
              <Link to="/about-us">
                <li className="hover:text-yellow-300 cursor-pointer">
                  About-us
                </li>
              </Link>
              <Link to="/day-care">
                <li className="hover:text-yellow-300 cursor-pointer">
                  Day-Care
                </li>
              </Link>
              <Link to="/dog-boardings">
                <li className="hover:text-yellow-300 cursor-pointer">
                  Boardings
                </li>
              </Link>
              <Link to="/dog-grooming">
                <li className="hover:text-yellow-300 cursor-pointer">
                  Grooming
                </li>
              </Link>
              <Link to="/our-breeds-and-stud-service">
                <li className="hover:text-yellow-300 cursor-pointer">
                Our Breeds and Stud services
                </li>
              </Link>
              <Link to="/gallery">
                <li className="hover:text-yellow-300 cursor-pointer">
                  Gallery
                </li>
              </Link>
              <Link to="/contact-us">
                <li className="hover:text-yellow-300 cursor-pointer">
                contact-us
                </li>
              </Link>
            </ul>
          </div>
        </div>

      

        {/* Copyright */}
        <div className="mt-8 text-center">
          <p className="text-sm">&copy; 2024 Tuffy Kennels. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
