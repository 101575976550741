import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import banner4 from "./images/banner4.png";
import huskyaugoti from "./images/husky-augoti.jpg";
import poodle from "./images/poodle.jpg"

const BreedsAndStudService = () => {




    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({ duration: 1000, easing: "ease-in-out" });
    }, []);

    return (
        <section className="breeds-stud-section py-12 px-6 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
            <div className="container mx-auto text-center">
                {/* Header Section */}
                <h2 className="text-3xl sm:text-4xl font-bold text-pink-800 mb-4" data-aos="fade-down">
                    Our Breeds and Stud Services
                </h2>
                <p className="text-lg sm:text-xl text-gray-700 mb-8" data-aos="fade-down" data-aos-delay="100">
                    High-quality puppies and professional stud services for your beloved pets.
                </p>


                {/* Image */}
                <div className="mb-8" data-aos="fade-up" data-aos-delay="200">
                    <img
                        src={banner4}
                        alt="Dog Boarding"
                        className="w-full h-auto rounded-lg shadow-lg"
                    />
                </div>
                {/* Breeds Section */}
                <div className="mt-8  p-6 " data-aos="fade-up">
                    <h3 className="text-2xl font-semibold text-green-800">Our Available Breeds</h3>
                    <p className="mt-4 text-lg text-gray-700">
                        Explore our selection of well-bred puppies that are healthy, vaccinated, and ready to become part of your family.
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                        {/* Breed Card: Labrador Retriever */}
                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7fhzVusq2TwUtFAQOP_xEXEFsE7AMaHGz3w&s"
                                alt="Labrador Retriever"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-green-700">Labrador Retriever</h4>
                                <p className="mt-2 text-gray-600">
                                    Labrador Retrievers are friendly, intelligent, and known for their gentle temperament.
                                </p>
                                <Link to={`/breed-details/${2}`} >
                                    <button className="mt-4 px-4 py-2 bg-green-600 text-white text-sm font-bold rounded hover:bg-green-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/* Breed Card: Golden Retriever */}
                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/premium-photo/golden-retriever-illustration-by-generative-ai_75474-8723.jpg?w=1060"
                                alt="Golden Retriever"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-yellow-700">Golden Retriever</h4>
                                <p className="mt-2 text-gray-600">
                                    Golden Retrievers are known for their loyalty, intelligence, and friendly nature, perfect for families.
                                </p>
                                <Link to={`/breed-details/${1}`} >
                                    <button className="mt-4 px-4 py-2 bg-yellow-600 text-white text-sm font-bold rounded hover:bg-yellow-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/* Breed Card: German Shepherd */}
                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/premium-photo/portrait-german-shepherd-long-hair-puppy-dog_802764-2182.jpg?w=900"
                                alt="German Shepherd"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-blue-700">German Shepherd</h4>
                                <p className="mt-2 text-gray-600">
                                    German Shepherds are highly trainable, protective, and ideal for working or family environments.
                                </p>
                                <Link to={`/breed-details/${4}`} >
                                    <button className="mt-4 px-4 py-2 bg-blue-600 text-white text-sm font-bold rounded hover:bg-blue-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/* Add more breed cards */}
                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/free-photo/isolated-top-view-picture-cute-beagle-dog-playing-green-grass-outdoors-park-sunny-day-looking-up-attentively-waiting-command-from-its-owner-adorable-tricolor-puppy-walk_343059-3083.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Beagle"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-orange-700">Beagle</h4>
                                <p className="mt-2 text-gray-600">
                                    Beagles are small, friendly, and great with children, known for their playful and curious nature.
                                </p>
                                <Link to={`/breed-details/${3}`} >
                                    <button className="mt-4 px-4 py-2 bg-orange-600 text-white text-sm font-bold rounded hover:bg-orange-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/free-photo/spitz-dog-studio-neutral-backdrop_155003-5196.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Pomeranian"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-pink-700">Pomeranian</h4>
                                <p className="mt-2 text-gray-600">
                                    Pomeranians are small, fluffy, and full of personality, making them a favorite for apartment living.
                                </p>
                                <Link to={`/breed-details/${5}`} >
                                    <button className="mt-4 px-4 py-2 bg-pink-600 text-white text-sm font-bold rounded hover:bg-pink-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/free-photo/portrait-adorable-doberman-dog_23-2151770004.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Pomeranian"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-red-700">Doberman</h4>
                                <p className="mt-2 text-gray-600">
                                    Dobermans are intelligent, loyal, and protective dogs, known for their athletic build and devotion to their families.
                                </p>
                                <Link to={`/breed-details/${6}`} >
                                    <button className="mt-4 px-4 py-2 bg-red-600 text-white text-sm font-bold rounded hover:bg-red-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src={huskyaugoti}
                                alt="Pomeranian"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-yellow-700">Husky</h4>
                                <p className="mt-2 text-gray-600">
                                    Huskies are known for their striking blue or multicolored eyes, thick double coats, and friendly demeanor. They are energetic, independent, and love the outdoors.
                                </p>

                                <Link to={`/breed-details/${7}`} >
                                    <button className="mt-4 px-4 py-2 bg-yellow-600 text-white text-sm font-bold rounded hover:bg-yellow-500">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>



                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src={poodle}
                                alt="Pomeranian"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-indigo-500">Poodle</h4>
                                <p className="mt-2 text-gray-600">
                                    Poodles are highly intelligent, trainable, and friendly dogs. They come in three sizes: standard, miniature, and toy. Known for their curly, hypoallergenic coat, Poodles are great for families and are often seen in dog sports and competitions.
                                </p>

                                <Link to={`/breed-details/${8}`} >
                                    <button className="mt-4 px-4 py-2 bg-indigo-500 text-white text-sm font-bold rounded hover:bg-indigo-400">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/free-photo/shih-tzu-dog-relaxing-sofa-living-room_637285-4652.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Shih Tzu"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-black">Shih Tzu</h4>
                                <p className="mt-2 text-gray-600">
                                    Shih Tzus are affectionate, playful, and loyal companion dogs with a charming personality. Their luxurious double coat and friendly demeanor make them excellent family pets. They are well-suited for both apartments and larger homes due to their small size and adaptable nature.
                                </p>

                                <Link to={`/breed-details/${9}`} >
                                    <button className="mt-4 px-4 py-2 bg-black text-white text-sm font-bold rounded hover:bg-black">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2021/03/09135854/Rottweiler-puppy-fetching-a-toy-in-the-grass.jpeg"
                                alt="Rottweiler"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-emerald-600">Rottweiler</h4>
                                <p className="mt-2 text-gray-600">
                                    Rottweilers are strong, confident, and loyal dogs, known for their protective nature. They are highly intelligent and trainable, making them excellent guard dogs and companions for experienced owners. Their robust build and commanding presence require regular exercise and socialization.
                                </p>

                                <Link to={`/breed-details/${10}`} >
                                    <button className="mt-4 px-4 py-2 bg-emerald-500 text-white text-sm font-bold rounded hover:bg-emerald-600">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEkfYQwiGY0daZLS5uEzKCj1W7KxNHpDv9hA&s"
                                alt="Great Dane"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-amber-500">Great Dane</h4>
                                <p className="mt-2 text-gray-600">
                                    Great Danes are giant dogs known for their impressive size and gentle, friendly nature. Despite their imposing stature, they are often referred to as "gentle giants." They are loyal, affectionate, and good with families, making them excellent companions. Regular exercise is important due to their size, but they generally enjoy a calm and laid-back lifestyle.
                                </p>

                                <Link to={`/breed-details/${11}`} >
                                    <button className="mt-4 px-4 py-2 bg-amber-500 text-white text-sm font-bold rounded hover:bg-amber-400">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
                            <img
                                src="https://img.freepik.com/premium-photo/american-bully-dog-running-lawn-green-grass-forest_124865-633.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Pit Bull"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-rose-600">Pit Bull</h4>
                                <p className="mt-2 text-gray-600">
                                    Pit Bulls are strong, athletic dogs known for their courage and loyalty. Despite their often misunderstood reputation, they are gentle and affectionate with their families, especially when raised in a positive environment. They require proper training and socialization to become well-rounded companions. Their high energy levels make them great for active owners who can provide regular exercise and mental stimulation.
                                </p>

                                <Link to={`/breed-details/${12}`} >
                                    <button className="mt-4 px-4 py-2 bg-rose-600 text-white text-sm font-bold rounded hover:bg-rose-600">
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </div>




                    </div>
                </div>


                {/* Stud Services Section */}
                <div className="mt-8  p-6  flex flex-col lg:flex-row items-center lg:items-start" data-aos="fade-up" data-aos-delay="200">
                    {/* Left Content */}
                    <div className="lg:w-1/2">
                        <h3 className="text-2xl font-semibold text-pink-800">Stud Service</h3>

                        <p className="mt-4 text-lg text-gray-700">
                            We provide professional stud services with well-maintained, high-quality stud dogs. Our stud dogs are:
                        </p>
                        <ul className="mt-4 text-lg text-left text-gray-700 list-disc pl-6">
                            <li>Screened for genetic health and temperament.</li>
                            <li>Compatible with various breeds and sizes.</li>
                            <li>Supervised during the breeding process for safety and comfort.</li>
                        </ul>
                        <p className="mt-4 text-lg text-gray-700">
                            Ethical breeding practices are at the core of our services, ensuring the health and well-being of both dogs involved.
                        </p>
                        <div className="mt-8 text-center">
                            <Link to="/contact-us">
                                <div
                                    className="inline-block px-6 py-3 bg-pink-600 text-white rounded-full shadow-lg hover:bg-pink-700 transition-colors duration-300"
                                    data-aos="fade-up" // Adding fade-up animation to the button
                                >
                                    Send Enquiry
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* Right Image */}
                    <div className="lg:w-1/2 lg:pl-8 mt-6 lg:mt-0 flex justify-center items-center">
                        <img
                            src="https://img.freepik.com/premium-photo/combing-hair-dog-shiba-inu-breed-shiba-inu-dog-undergoing-procedure-groomer-salon_357889-8204.jpg?w=900"
                            alt="Stud Service"
                            className="rounded-lg shadow-lg w-full max-w-md"
                        />
                    </div>
                </div>


                {/* Call-to-Action Block */}
                <div
                    className="mt-16 text-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white"
                    data-aos="fade-up"
                >
                    <h3 className="text-xl md:text-3xl font-bold">Join the Tuffy Kennels Family Today!</h3>
                    <p className="mt-4 text-sm md:text-lg">
                        Whether you’re looking for the perfect puppy or a safe place for your dog to stay, we’re here for
                        you. Contact us now!
                    </p>
                    <Link to="/contact-us">
                        <button className="mt-6 px-6 py-3 bg-white text-blue-600 rounded-lg shadow hover:bg-gray-100">
                            Get Started
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BreedsAndStudService;
