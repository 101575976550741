import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // AOS styles
import image1 from "./images/dogImg.jpg"
import image2 from "./images/dog2.jpg"

const Gallery = () => {
  const items = [
    {
      type: "image",
      src: image1, // Replace with actual image paths
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEkV7IDSJT4kgSBsA5NjuWJ-Mr-VgxyxfZ6A&s',
      alt: "Dog in Kennel 2",
    },
    {
      type: "video",
    src: "https://www.youtube.com/embed/Dak_EUBUJLs?si=KLU5jIX3oAzmg4a1",
      alt: "Tuffy Kennels Introduction",
    },
    {
      type: "image",
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYjEq5W2sDnh2v5o-t5XobKTnN8Pj_gOSJRQ&s',
      alt: "Dog Playing Outside",
    },
    {
      type: "video",
      src: "https://www.youtube.com/embed/_SCumGxxxcI?si=MJH-OAEXhy0OMJiu", // Example YouTube video
      alt: "Kennels Tour",
    },
    {
      type: "image",
      src: image2,
      alt: "Dog Sleeping",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Trigger animation only once
    });
  }, []);

  return (
    <section className="gallery py-16 px-4 bg-gradient-to-r from-blue-100 via-white to-pink-100">
      <h2
        className="text-3xl sm:text-4xl text-center font-semibold text-blue-700"
        data-aos="fade-up"
      >
        Our Gallery
      </h2>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {items.map((item, index) => (
          <div
            key={index}
            className="gallery-item p-4 bg-white rounded-lg shadow-lg overflow-hidden"
            data-aos="zoom-in"
            data-aos-delay={index * 200}
          >
            {item.type === "image" ? (
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-48 object-cover rounded-lg transition-transform duration-300 hover:scale-105"
              />
            ) : (
              <iframe
                width="100%"
                height="200"
                src={item.src}
                title={item.alt}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg transition-transform duration-300 hover:scale-105"
              />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
