import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import daycarebanner from "./images/daycarebanner.png";

const DogDaycare = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({ duration: 1000, easing: "ease-in-out" });
    }, []);

    return (
        <section className="daycare-section py-12 px-6 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl sm:text-4xl font-bold text-purple-800" data-aos="fade-down">
                    Dog Daycare Services
                </h2>
                <p className="mt-4 text-xl sm:text-2xl text-gray-700" data-aos="fade-down" data-aos-delay="100">
                    Providing the perfect combination of care, comfort, and fun for your beloved pets.
                </p>

                {/* Adding the image here */}
                <div className="mt-8" data-aos="fade-up">
                    <img
                        src="https://di-uploads-development.dealerinspire.com/iratoyotaofmanchester/uploads/2019/09/Happy-Dog-banner.jpg"
                        alt="Dog Daycare"
                        className="w-full h-auto rounded-lg shadow-md "
                    />
                </div>

                {/* Daycare Information Blocks */}
                <div className="mt-8 space-y-8 text-left">
                    {/* Individual Cages and Spaces */}
                    <div className="bg-gradient-to-r from-green-300 via-blue-300 to-purple-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="200">
                        <h3 className="text-2xl font-semibold text-purple-800">Separate Cages and Spacious Areas</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            We provide separate cages for each dog to ensure their comfort and privacy. Our facilities include fully ventilated large spaces tailored for individual needs. Dogs can enjoy their own secure and relaxing environment, designed to make them feel at home.
                        </p>
                    </div>

                    {/* Individual Walks */}
                    <div className="bg-gradient-to-r from-yellow-300 via-orange-300 to-red-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="300">
                        <h3 className="text-2xl font-semibold text-purple-800">Individual Walks Twice a Day</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            Every dog is taken for an individual walk twice daily, lasting between 30 minutes to 1 hour. This ensures they get the exercise and attention they need to stay happy and healthy.
                        </p>
                    </div>

                    {/* Cage Sizes */}
                    <div className="bg-gradient-to-r from-purple-300 via-pink-300 to-red-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="400">
                        <h3 className="text-2xl font-semibold text-purple-800">Separate Kennels Based on Dog Sizes</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            We offer kennels of different sizes to accommodate dogs of all breeds and sizes. Our kennel options include:
                        </p>
                        <ul className="mt-4 text-lg text-gray-800 list-disc pl-6">
                            <li>3x6 feet cages for smaller dogs.</li>
                            <li>4x6 feet cages for larger breeds.</li>
                        </ul>
                        <p className="mt-4">
                            Each kennel is designed for maximum comfort, ensuring your dog feels secure and relaxed.
                        </p>
                    </div>

                    {/* Feeding Options */}
                    <div className="bg-gradient-to-r from-blue-300 via-green-300 to-teal-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="500">
                        <h3 className="text-2xl font-semibold text-purple-800">Nutritious Feeding Options</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            We provide two meals a day, customized to your dog’s dietary preferences. Our feeding options include:
                        </p>
                        <ul className="mt-4 text-lg text-gray-800 list-disc pl-6">
                            <li>Premium dog food: Pedigree, Royal Canin, or Kennel Greek.</li>
                            <li>Special meal: Chicken and rice (if your dog is accustomed to eating chicken).</li>
                        </ul>
                        <p className="mt-4">
                            You can choose the meal plan that suits your dog best, ensuring they enjoy a healthy and satisfying diet.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DogDaycare;
