import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // AOS styles
import { Swiper, SwiperSlide } from 'swiper/react';  // Import Swiper components
import 'swiper/css';  // Core Swiper styles
import 'swiper/css/navigation';  // Navigation styles
import 'swiper/css/pagination'; 
import { Navigation } from 'swiper';

const GoogleReviews = () => {
  const reviews = [
    {
      name: "Ravi Kumar H M",
      rating: 5,
      comment: "Great place for boarding your fur babies. Abhishek and his associate ensure good care for boarding pets with separate cages and feeding systems.",
    },
    {
      name: "Rokkam Netaji",
      rating: 5,
      comment: "Good quality Brides are available and Mr,Abhi is really giving at good price , i recommended to my friends as well they are also happy",
    },
    {
      name: "Sunitha Srinivasan",
      rating: 5,
      comment: "They take good care of pets like babies. Well maintained kennel. They keep it very clean. Best option for pet boarding at reasonable price. Very friendly people",
    },
    {
      name: "Gangadhar S",
      rating: 4,
      comment: "Best place for leave our pets for boarding Am satisfied with the care they took",
    },
    {
      name: "Puneeth raj",
      rating: 5,
      comment: "Place with peace #well deserved place to take caring of pets n puppies",
    },
    {
      name: "Vijay Karli",
      rating: 5,
      comment: "Nice Place, Abhi treats dogs like his friends 😊......",
    },
   
  ];

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Animation triggers once
    });
  }, []);

  return (
    <section
      className="google-reviews py-10 px-4 "
      data-aos="fade-up" 
    >
      <h2
        className="text-3xl sm:text-4xl text-center font-semibold text-blue-700"
        data-aos="fade-up" 
      >
        What Our Customers Say
      </h2>
      <div className="mt-8">
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        loop={true}
        autoplay={{
          delay: 1000,  
          disableOnInteraction: false,  
        }}
        pagination={{ clickable: true }}  
        breakpoints={{
          320: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 4 },
        }}
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <div className="review-card p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl font-bold text-gray-800">{review.name}</h3>
              <div className="mt-2 text-yellow-500">
                {"⭐".repeat(review.rating)}
              </div>
              <p className="mt-4 text-gray-600">{review.comment}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
      <div className="mt-8 text-center">
        <a
          // href="https://g.page/r/YOUR_PLACE_ID/review" 
          href="https://www.google.com/search?sca_esv=3cd05ba942434f1d&rlz=1CDGOYI_enIN1119IN1119&hl=en-GB&cs=0&q=tuffy+kennels+and+boarding+bangalore+reviews&spell=1&sa=X&ved=2ahUKEwieuKTNo6WKAxXY9zgGHTGtDlwQBSgAegQICxAB&biw=393&bih=665&dpr=3#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D&trex=m_t:lcl_akp,rc_f:nav,rc_ludocids:8495376067028644637,rc_q:Tuffy%2520Kennels%2520%2526%2520Boardings,ru_q:Tuffy%2520Kennels%2520%2526%2520Boardings,trex_id:yop13e" 
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
          data-aos="fade-up" // Adding fade-up animation to the button
        >
          Write a Review on Google
        </a>
      </div>
    </section>
  );
};

export default GoogleReviews;
