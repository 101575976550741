import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './AboutUs';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import Home from './Home';
import Services from './Services';
import ContactUs from './Contactus';
import Gallery from './Gallery';
import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import DogDaycare from './DogDaycare';
import DogBoarding from './Boarding';
import Grooming from './Grooming';
import BreedsAndStudService from './BreedsandStud';
import BreedDetails from './BreedsDetails';
import CostPrice from './CostPrice';
import TermsAndConditions from './Termscondition';

function App() {
  return (
    <Router>
      <div className="App relative">
        <Header />
        <div className="container mx-auto ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/day-care" element={<DogDaycare />} />
            <Route path="/dog-boardings" element={<DogBoarding />} />
            <Route path="/dog-grooming" element={<Grooming />} />
            <Route path="/our-breeds-and-stud-service" element={<BreedsAndStudService />} />
            <Route path="/breed-details/:id" element={<BreedDetails  />} />
            <Route path="/cost-price" element={<CostPrice  />} />
            <Route path="/terms-condition" element={<TermsAndConditions  />} />
          </Routes>
        </div>
        <Footer />


{/* WhatsApp Button */}
<a
  href="https://wa.me/916363507022?text=Hi%2C%20I%20would%20like%20to%20know%20more%20about%20Tuffy%20Kennels%20services." 
  className="fixed bottom-24 right-6 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300 flex items-center justify-center sm:hidden"
  aria-label="Chat on WhatsApp"
  target="_blank"
  rel="noopener noreferrer"
>
  <FaWhatsapp size={24} />
</a>

{/* Call Button */}
<a
  href="tel:+916363507022"
  className="fixed bottom-6 right-6 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 flex items-center justify-center"
  aria-label="Call Us"
>
  <FaPhoneAlt size={24} />
</a>
      </div>
    </Router>
  );
}

export default App;

// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import AboutUs from './AboutUs';
// import './App.css';
// import Footer from './Footer';
// import Header from './Header';
// import Home from './Home';
// import Services from './Services';
// import ContactUs from './Contactus';
// import Gallery from './Gallery';
// import { FaWhatsapp } from "react-icons/fa";
// import { FaPhoneAlt } from "react-icons/fa";
// import DogDaycare from './DogDaycare';
// import DogBoarding from './Boarding';
// import Grooming from './Grooming';
// import BreedsAndStudService from './BreedsandStud';
// import BreedDetails from './BreedsDetails';

// function App() {
//   const [showHeader, setShowHeader] = useState(true);
//   const [lastScrollY, setLastScrollY] = useState(0);

//   const handleScroll = () => {
//     const currentScrollY = window.scrollY;
//     if (currentScrollY > lastScrollY && currentScrollY > 50) {
//       // Scrolling down and passed 50px
//       setShowHeader(false);
//     } else {
//       // Scrolling up or near the top
//       setShowHeader(true);
//     }
//     setLastScrollY(currentScrollY);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [lastScrollY]);

//   return (
//     <Router>
//       <div className="App relative">
//         <div className={`fixed top-0 left-0 right-0 z-50 transition-transform duration-300 ${showHeader ? 'translate-y-0' : '-translate-y-full'} bg-white`}
//         style={{backgroundColor:"white"}}>
//           <Header />
//         </div>
//         <div className="container mx-auto mt-16"> {/* Adjust margin-top for header height */}
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/about-us" element={<AboutUs />} />
//             <Route path="/services" element={<Services />} />
//             <Route path="/contact-us" element={<ContactUs />} />
//             <Route path="/gallery" element={<Gallery />} />
//             <Route path="/day-care" element={<DogDaycare />} />
//             <Route path="/dog-boardings" element={<DogBoarding />} />
//             <Route path="/dog-grooming" element={<Grooming />} />
//             <Route path="/our-breeds-and-stud-service" element={<BreedsAndStudService />} />
//             <Route path="/breed-details/:id" element={<BreedDetails />} />
//           </Routes>
//         </div>
//         <Footer />

//         {/* WhatsApp Button */}
//         <a
//           href="https://wa.me/919538322299?text=Hi%2C%20I%20would%20like%20to%20know%20more%20about%20Tuffy%20Kennels%20services."
//           className="fixed bottom-24 right-6 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300 flex items-center justify-center sm:hidden"
//           aria-label="Chat on WhatsApp"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <FaWhatsapp size={24} />
//         </a>

//         {/* Call Button */}
//         <a
//           href="tel:+919113089942"
//           className="fixed bottom-6 right-6 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 flex items-center justify-center"
//           aria-label="Call Us"
//         >
//           <FaPhoneAlt size={24} />
//         </a>
//       </div>
//     </Router>
//   );
// }

// export default App;
