import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Services = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const services = [
        {
            title: "Daycare",
            description: "Drop your dog off for the day for socialization and playtime with other dogs, while you take care of your responsibilities.",
            color: "bg-gradient-to-r from-blue-100 to-purple-200",
            animation: "fade-up",
        },
        {
            title: "Overnight Boarding",
            description: "Our overnight boarding service ensures your dog gets the same level of comfort, safety, and care as they would at home.",
            color: "bg-gradient-to-r from-green-100 to-teal-200",
            animation: "fade-up",
        },
        {
            title: "Training",
            description: "We offer training services to ensure your dog is well-behaved and comfortable with other pets and humans.",
            color: "bg-gradient-to-r from-pink-100 to-red-200",
            animation: "fade-up",
        },
        {
            title: "Grooming",
            description: "Professional grooming services to keep your dog looking and feeling their best.",
            color: "bg-gradient-to-r from-yellow-100 to-orange-200",
            animation: "zoom-in",
        },
        {
            title: "Veterinary Care",
            description: "On-site veterinary care to address your dog’s health needs promptly and professionally.",
            color: "bg-gradient-to-r from-indigo-100 to-purple-300",
            animation: "zoom-in",
        },
        {
            title: "Puppy Adoption",
            description: "Find your perfect furry friend from our range of happy, healthy, and high-breed puppies.",
            color: "bg-gradient-to-r from-gray-100 to-blue-200",
            animation: "zoom-in",
        },
        {
            title: "Fitness Programs",
            description: "Keep your dog in shape with our custom exercise and agility training programs.",
            color: "bg-gradient-to-r from-cyan-100 to-lime-200",
            animation: "fade-up",
        },
        {
            title: "Special Needs Care",
            description: "Tailored care for dogs with unique requirements, ensuring comfort and safety.",
            color: "bg-gradient-to-r from-fuchsia-100 to-purple-200",
            animation: "zoom-in",
        },
        {
            title: "Dog Events",
            description: "Join fun events like doggy playdates, competitions, and training workshops.",
            color: "bg-gradient-to-r from-red-100 to-pink-300",
            animation: "zoom-in",
        },
    ];

    return (
        <section id="services" className="py-12">
            <div className="container mx-auto text-center px-4">
                {/* Title Section */}
                <h2
                    className="text-3xl md:text-5xl font-bold text-purple-800"
                    data-aos="fade-down"
                >
                    Our Services
                </h2>
                <p
                    className="mt-4 text-gray-600 text-sm md:text-lg"
                    data-aos="fade-down"
                    data-aos-delay="200"
                >
                    Tailored to meet all your dog's needs, from playtime to premium care!
                </p>

                {/* Services Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className={`service-item p-6 rounded-lg shadow-lg text-center transform hover:scale-105 transition-transform duration-300 ${service.color}`}
                            data-aos={service.animation}
                            data-aos-delay={`${index * 100}`}
                        >
                            <h3 className="text-xl md:text-2xl font-bold text-purple-800">
                                {service.title}
                            </h3>
                            <p className="mt-4 text-gray-700">{service.description}</p>
                        </div>
                    ))}
                </div>

                {/* Call-to-Action */}
                <div
                    className="mt-16 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white text-center"
                    data-aos="fade-up"
                >
                    <h3 className="text-2xl md:text-4xl font-bold">Your Dog Deserves the Best!</h3>
                    <p className="mt-4 text-sm md:text-lg">
                        Contact us today to book a service or learn more about our offerings!
                    </p>
                    <Link to="/contact-us">
                    <button className="mt-6 px-6 py-3 bg-white text-purple-600 font-semibold rounded-lg shadow hover:bg-gray-100 transition">
                        Get in Touch
                    </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Services;
