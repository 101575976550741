import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceCount = () => {

       useEffect(() => {
              AOS.init({ duration: 500, easing: "ease-in-out" });
          }, []);

  const finalCounts = {
    daycare: 1500,
    boarding: 200,
    grooming: 750,
    puppySale: 7,
  };

  // State for each count
  const [counts, setCounts] = useState({
    daycare: 0,
    boarding: 0,
    grooming: 0,
    puppySale: 0,
  });

  const [startCounting, setStartCounting] = useState(false); // To control when to start the count
  const countRef = useRef(null); // Ref for the Service Count block

  // Function to increment counts
  useEffect(() => {
    if (!startCounting) return;

    const interval = 10; // Speed of increment (ms)
    const incrementSteps = {
      daycare: Math.ceil(finalCounts.daycare / 100),
      boarding: Math.ceil(finalCounts.boarding / 100),
      grooming: Math.ceil(finalCounts.grooming / 100),
      puppySale: Math.ceil(finalCounts.puppySale / 100),
    };

    const intervalId = setInterval(() => {
      setCounts((prevCounts) => {
        let updatedCounts = { ...prevCounts };
        let completed = true;

        for (const key in updatedCounts) {
          if (updatedCounts[key] < finalCounts[key]) {
            updatedCounts[key] += incrementSteps[key];
            completed = false;
          }
        }

        // Stop the interval when all counts reach their final values
        if (completed) clearInterval(intervalId);
        return updatedCounts;
      });
    }, interval);

    return () => clearInterval(intervalId);
  }, [startCounting, finalCounts]);

  // Intersection Observer to start counting when visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCounting(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the block is visible
    );

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (countRef.current) observer.unobserve(countRef.current);
    };
  }, []);

  return (
    <div ref={countRef} className="mt-8 grid grid-cols-1 md:grid-cols-4 gap-6 text-center" data-aos="fade-up">
      {/* Daycare */}
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-blue-800">Daycare & Boardings</h3>
        <p className="mt-4 text-4xl font-bold text-green-700">{counts.daycare}+</p>
      </div>

      {/* Boarding */}
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-blue-800">Groomings</h3>
        <p className="mt-4 text-4xl font-bold text-green-700">{counts.boarding}+</p>
      </div>

      {/* Grooming */}
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-blue-800">Puppy Sales</h3>
        <p className="mt-4 text-4xl font-bold text-green-700">{counts.grooming}+</p>
      </div>

      {/* Puppy Sale */}
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-blue-800">Year of Service</h3>
        <p className="mt-4 text-4xl font-bold text-green-700">{counts.puppySale}+</p>
      </div>
    </div>
  );
};

export default ServiceCount;
